.calendarWpr {
  gap: 10px;
  justify-content: flex-end;
}
.calendarWpr li {
  background: var(--primarypurple100);
  padding: 5px 15px;
  border-radius: 5px;
  width: 125px;
  color: var(--primarypurple500);
}
.cstmcalendarflex {
  justify-content: flex-end;
  gap: 10px;
  align-items: flex-start;
  position: relative;
}
.cstmcalendarflex .cstmcalendarbtn button {
  background: var(--primarypurple100) !important;
  color: var(--primarypurple500);
  padding: 10px;
  border-radius: 6px;
  border: 1px solid var(--primarypurple500);
}
.cstmcalendarflex .cstmcalendarbtn button.btn-gupshup-primary,
.cstmcalendarflex .cstmcalendarbtn button.btn-gupshup-primary:hover {
  background: var(--primarypurple500) !important;
  color: var(--whitecolor);
  padding: 7px 15px;
}
.cstmcalendarflex .cstmcalendarbtn button:hover,
.cstmcalendarflex .cstmcalendarbtn button.active {
  background: var(--primarypurple500) !important;
  color: var(--whitecolor) !important;
}
.cstmcalendarflex .cstmcalendarbtn {
  gap: 10px;
}
.cstmcalendarflex .rdrDateRangePickerWrapper {
  border-radius: 6px;
  overflow: hidden;
}
.cstmcalendarflex .rdrStaticRange:hover .rdrStaticRangeLabel,
.cstmcalendarflex .rdrStaticRange:focus .rdrStaticRangeLabel {
  background: var(--primarypurple400);
  color: var(--whitecolor);
}
.cstmcalendarflex .rdrEndEdge,
.cstmcalendarflex .rdrStartEdge {
  color: var(--primarypurple400) !important;
}
.cstmcalendarflex .rdrDayHovered .rdrDayEndPreview,
.cstmcalendarflex .rdrDayHovered .rdrDayStartPreview {
  border: 1px solid var(--primarypurple400) !important;
}
.cstmcalendarflex .rdrInRange {
  color: var(--primarypurple100) !important;
}
.cstmcalendarflex .rdrInRange + .rdrDayNumber span,
.cstmcalendarflex .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.cstmcalendarflex
  .rdrDay:not(.rdrDayPassive)
  .rdrSelected
  ~ .rdrDayNumber
  span {
  color: var(--primarypurple400) !important;
}
.cstmcalendarflex .rdrDayHovered.rdrDayActive .rdrDayEndPreview,
.cstmcalendarflex .rdrDayHovered.rdrDayActive .rdrDayStartPreview {
  border: 0 !important;
}
.cstmcalendarflex .rdrDayDisabled {
  background-color: var(--neutralgray200);
}
.cstmcalendarflex .rdrDayToday .rdrDayNumber span:after {
  background: var(--primarypurple400);
}
.datePickerWrapper .applyCancelWpr .btn.btn-gupshup-primary {
  background: var(--primarypurple400);
}
.datePickerWrapper .applyCancelWpr .btn.btn-cancel {
  background-color: var(--neutralgray400);
}
.datePickerWrapper {
  position: absolute;
  top: 46px;
  right: 0;
  border: 1px solid var(--neutralgray400);
  background: #fff;
  z-index: 5;
}

.datePickerWrapper .applyCancelWpr {
  justify-content: flex-end;
  margin: 15px 0;
  gap: 15px;
  padding: 0 15px;
}

.cstmcalendarflex .cstmcalendarbtn button {
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins";
}
