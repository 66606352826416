.pagination > li > a {
	background-color: white;
	color: var(--primarypurple500);
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
	color: var(--primarypurple500);
	background-color: #eee;
	border-color: #ddd;
}

.pagination > .active > a {
	color: white;
	background-color: var(--primarypurple500) !important;
	border: solid 1px var(--primarypurple500) !important;
}

.pagination > .active > a:hover {
	color: white;
	background-color: var(--primarypurple500) !important;
	border: solid 1px var(--primarypurple500);
}
