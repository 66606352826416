.createCampaignWpr .form-group {
  flex-basis: 50%;
}
.createCampaignWpr .schedule .form-group {
  flex-basis: auto;
}
.form-container .wdth100 .form-group {
  flex-grow: 1;
}
.btntypewpr,
.quickbtntypewpr {
  gap: 15px;
  align-items: normal;
}
.btntypewpr .form-group:first-child {
  flex-shrink: 0;
  width: 130px;
}
.btntypewpr .form-group:last-child,
.quickbtntypewpr .form-group:last-child {
  flex-shrink: 0;
  display: flex;
  align-items: end;
}
.quickbtntypewpr .form-group:first-child {
  width: 100%;
}
.btntypewpr .form-group:last-child button,
.quickbtntypewpr .form-group:last-child button {
  color: #fff;
  border-radius: 6px;
  background-color: var(--primarypurple500);
  padding: 10px 15px;
  margin-left: 5px;
  margin-right: 5px;
}
.campaignsubmitbtn input[type="submit"] {
  background-color: var(--primarypurple500);
  color: #fff;
}
.createCampaignCalender .rdrDefinedRangesWrapper {
  width: auto;
}
.createCampaignCalender .rdrDateRangePickerWrapper {
  position: absolute;
  top: 40px;
  left: 0;
  border: 1px solid var(--neutralgray400);
  border-radius: 6px;
  overflow: hidden;
}
.createCampaignCalender .rdrMonthsHorizontal .rdrMonth {
  width: 100%;
}
.createCampaignCalender .rdrDateRangePickerWrapper .rdrInputRange {
  padding: 5px 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.createCampaignCalender .rdrEndEdge,
.createCampaignCalender .rdrStartEdge {
  color: var(--primarypurple400) !important;
}
.createCampaignCalender .rdrDateRangePickerWrapper {
  border-radius: 6px;
  overflow: hidden;
  z-index: 1;
}
.createCampaignCalender .rdrStaticRange:hover .rdrStaticRangeLabel,
.createCampaignCalender .rdrStaticRange:focus .rdrStaticRangeLabel {
  background: var(--primarypurple400);
  color: var(--whitecolor);
}
.createCampaignCalender .rdrEndEdge,
.createCampaignCalender .rdrStartEdge {
  color: var(--primarypurple400) !important;
}
.createCampaignCalender .rdrDayHovered .rdrDayEndPreview,
.createCampaignCalender .rdrDayHovered .rdrDayStartPreview {
  border: 1px solid var(--primarypurple400) !important;
}
.createCampaignCalender .rdrInRange {
  color: var(--primarypurple100) !important;
}
.createCampaignCalender .rdrInRange + .rdrDayNumber span,
.createCampaignCalender
  .rdrDay:not(.rdrDayPassive)
  .rdrInRange
  ~ .rdrDayNumber
  span,
.createCampaignCalender
  .rdrDay:not(.rdrDayPassive)
  .rdrSelected
  ~ .rdrDayNumber
  span {
  color: var(--primarypurple400) !important;
}
.createCampaignCalender .rdrDayHovered.rdrDayActive .rdrDayEndPreview,
.createCampaignCalender .rdrDayHovered.rdrDayActive .rdrDayStartPreview {
  border: 0 !important;
}
.createCampaignCalender .rdrDayDisabled {
  background-color: var(--neutralgray200);
}
.createCampaignCalender .rdrDayToday .rdrDayNumber span:after {
  background: var(--primarypurple400);
}
.createCampaignCalender button.btn-gupshup-primary,
.createCampaignCalender button.btn-gupshup-primary:hover {
  background: var(--primarypurple500) !important;
  color: var(--whitecolor);
  padding: 7px 15px;
}
.createCampaignCalender button:hover {
  background: transparent !important;
  color: #1d2429 !important;
}
/* .createCampaignCalender button:hover .rdrDayNumber span {
  color: #1d2429 !important;
} */
.createCampaignCalender .rdrInRange + .rdrDayNumber span {
  color: #fff;
}
.iconsWrapper {
  justify-content: space-evenly;
}
.viewtxt {
  color: var(--primarypurple500);
  font-size: 14px;
}
.editIcon,
.cancelIcon,
.previewIcon {
  width: 28px;
  height: 28px;
  display: block;
  background-repeat: no-repeat;
  background-size: 60%;
  cursor: pointer;
  background-color: var(--primarypurple100);
  border-radius: 100%;
  background-position: center;
}
.editIcon:hover,
.cancelIcon:hover,
.previewIcon:hover {
  background-color: var(--primarypurple500);
}
.editIcon {
  background-image: url(../images/editIcon.svg);
}
.cancelIcon {
  background-image: url(../images/closeIcon.svg);
}
.previewIcon {
  background-image: url(../images/previewIconPurple.png);
}
.editIcon:hover {
  background-image: url(../images/editIconHover.svg);
}
.cancelIcon:hover {
  background-image: url(../images/closeIconHover.svg);
}
.previewIcon:hover {
  background-image: url(../images/previewIconWhite.png);
}
.positionRelative {
  position: relative;
}
/* .positionRelative .cstmcalendarflex{
  position: absolute;
  top: 0;
  right: 0;
} */
.header_add {
  float: right;
}
.header_add .gpbtn {
  color: #fff;
  border-radius: 6px;
  background-color: var(--primarypurple500);
  padding: 10px 15px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
}
.header_add .gpbtn:hover {
  cursor: pointer;
  border: none;
  background-color: var(--primarypurple500) !important;
  font-size: 12px;
}
.catalogue_container {
  padding: 15px 0px;
  border-bottom: 1px solid #ececec;
}
.catalogue_container:last-child {
  border-bottom: 0px solid #ececec;
}
.selectBox {
  margin: 0 5px;
  padding: 5px;
  border: 1px solid var(--primarypurple500);
  border-radius: 6px;
  color: var(--primarypurple500);
  background: var(--primarypurple100);
  cursor: pointer;
}
pre {
  white-space: pre-wrap;
}
.orderpreview p{
  font-size: 12px;
  color: #222 !important;
  margin-bottom: 10px;
}
.orderpreview label{
  font-weight: bold;
}
p{
  font-size: 12px;
  color: #222 !important;
}.header_add .gpbtn.mt28{
  margin-top: 28px;
}.right-side .boldcount{
	border-right:0px solid !important;
}
.mr-5{
  margin-right: 5px !important;
}
.ml15{
	margin-left: 15px !important;
  }
 