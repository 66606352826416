.dwnloadReport button {
  background: var(--primarypurple100) !important;
  color: var(--primarypurple500);
  padding: 5px;
  border-radius: 6px;
  border: 1px solid var(--primarypurple500);
}
.dwnloadReport button:hover {
  background: var(--primarypurple500) !important;
  color: var(--whitecolor) !important;
}
.reportSearchBox input {
  border: 1px solid var(--bordercolor);
  border-radius: 6px;
}
.reportPagination {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
}
.reportPagination button {
  text-align: center;
  color: var(--primarypurple500);
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background: transparent;
}
.reportPagination button.active {
  background: var(--primarypurple500);
  color: var(--whitecolor);
}

.dwnloadReport button .downloadIcon {
  width: 25px;
  height: 25px;
  display: block;
  background-image: url(https://dev-unify.gupshup.io/GenericComponent/resources/images/downloadIcon.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
.dwnloadReport button:hover .downloadIcon {
  background-image: url(https://dev-unify.gupshup.io/GenericComponent/resources/images/downloadIconHover.png);
}
